<template>
   <div id="logincontanier">
    <div class="logincontent">
      <div class="login_logo">
        <img src="../../public/images/logo.png" alt="..." style="height: 150px; max-height:210px;max-width:100%"/>
        <h2 style="letter-spacing:5px">{{this.$ProjectPlatformName}}</h2>
      </div>
      <div class="loginform_title">
        <div class="title_line_v"></div>
        账号登录
      </div>
      <div class="loginform">
        <el-form :model="ruleForm"  ref="ruleForm"  :rules="rules">
        <el-row><el-col :span="24">  <el-form-item prop="userName"> <el-input class="form-input-text" placeholder="用户名" v-model="ruleForm.userName" autofocus="true"  @keyup.enter="loginHandler"/></el-form-item> </el-col></el-row>
        <el-row><el-col :span="24"> <el-form-item prop="password"><el-input class="form-input-text" type="password" placeholder="密码" v-model="ruleForm.password" show-password  autocomplete="new-password"  @keyup.enter="loginHandler"/></el-form-item></el-col></el-row>
      <el-row>
        <el-col :span="24"><el-button type="primary" id="btn-login" @click="loginHandler">登录</el-button></el-col>
      </el-row>
    </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import http from '../utill/http.js'
export default {
  data() {
    return {
      bodyloading:"",
      ruleForm:{
      userName: '',
      password:''
      },
      rules: {
        userName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
      }
    }
  },
  methods: {
    loginHandler: async function () {
      this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
          this.bodyloading = this.$loading({
          lock: true,
          text: '登录中...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
            this.Authenticate().then((data)=>{
              if(data.success==true){
              window.sessionStorage.setItem("accessToken", data.result.accessToken);
          window.sessionStorage.setItem("nickName", this.ruleForm.userName);
          this.bodyloading.close();
          this.$router.push({path:'/home'});
              }
              else{
                this.bodyloading.close();
                this.$message.error('登录失败');  
                console.log('登录失败');
              }
          }).catch(()=>{
            this.bodyloading.close();
            this.$message.error('登录失败');  
            console.log('登录失败');})
          } else {
            this.$message.error('登录信息填写不完整');  
            console.log('error submit!!');
            return false;
          }
        });
      },
    Authenticate: async function () {
      try {
        const response =await http({
          method: 'post',
          url: '/api/TokenAuth/Authenticate',
          data: { "userNameOrEmailAddress": this.ruleForm.userName, "password": this.ruleForm.password }
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }

    }
  }
}
</script>

<style scoped>
 ::v-deep input:-internal-autofill-selected {
  background-color: transparent !important;
}
#logincontanier{
  position: relative;
  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  .logincontent{
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 8px 18px 0px rgba(26, 31, 42, 0.1);
    padding: 70px;
    border-radius: 16px;
    width: 560px;
    height: 580px;
  }
  .login_logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
      font-size: 35px;
      color: #FFFFFF;
    font-weight: 500;
    }
  }
  .loginform_title{
    font-size: 26px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    .title_line_v{
    width: 5px;
    height: 25px;
    margin-right: 10px;
    display: inline-block;
    background-color: #3E8EF7;
    }
  }
  .loginform{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
   .el-row {
  margin-bottom: 20px !important;
}
    ::v-deep .el-input__wrapper{
      background-color:rgba(255, 255, 255, 0.1) !important;
      box-shadow:none !important;
      border: none !important;
  }
  ::v-deep .el-input__inner{
color:#FFFFFF !important;
height: 50px;
  line-height: 50px;
  font-size: 20px;
  }
  }
}
#btn-login{
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
}
</style>

