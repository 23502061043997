<template>
  <div id="page" ref="zoom">
    <router-view></router-view>
  </div>
</template>

<script>
import drawMixin from '@/utill/drawMixin.js'
export default {
  name: 'App',
  mixins: [drawMixin]

}
</script>

<style>
@import url('../public/fonts/font.less');

html,
body {
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  -moz-user-select: none; 
  -khtml-user-select: none;
   user-select: none;
}
#app {
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-image:  url('../public/images/bg.png');
  /* background-image: linear-gradient(RGBA(10, 44, 101, 1),RGBA(10, 35, 86, 1)), url('../public/images/bg.png'); */
  background-color:#092356;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  -moz-user-select: none; 
  -khtml-user-select: none;
   user-select: none;

}

#page {
  position: fixed;
  width: 2560px;
  height: 1440px;

}
#page-body{
position: absolute;
top: 220px;
left: 0;
width: 100%;
display: flex;
justify-content: center;
}
.el-dialog__title{
  font-size:25px !important ;
}
.el-form-item{
  align-items: center !important;
}
.el-form-item__label{
  min-width: 120px !important;
  font-size:20px !important ;
  color: rgba(255, 255, 255, 0.45) !important;
  font-weight: bolder !important;
}


.el-input__inner,
.el-button{
  font-size: 20px !important;
}
 .el-form-item__error, .el-checkbox{
    font-size: 18px !important;
  }
  .el-tag,.el-checkbox__label{
    font-size: 18px !important;
  }
  .el-dialog{
  background: #1C1D20 !important;
}
.el-dialog__title{
  color: #fff !important;
  font-size: 25px !important;
}
</style>
