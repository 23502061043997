<template>
  <topheader_components></topheader_components>
  <div id="page-body">
    <div id="top-tabs">
      <div id="top_tab1" class="top_tabs_title istabactive" @click="linkto('admin')">用户管理</div>
      <div id="top_tab3" class="top_tabs_title" @click="linkto('chartconfig')">能耗监测配置</div>
    </div>
    <t-layout-page style="padding: 0px 50px;">
      <t-layout-page-item>
        <t-table title="用户管理" size="large" :table="table" :columns="table.columns" stripe isShowPagination="true">
          <template #toolbar>
            <el-button size="large" type="primary" @click="addUser">新建</el-button>
            <!-- <el-button size="large" type="danger">删除</el-button> -->
          </template>
        </t-table>
      </t-layout-page-item>
    </t-layout-page>
  </div>
  <footer_components></footer_components>
  <el-dialog v-model="centerDialogVisible" :title="dialogtitle" width="600" close="closedialog('refuserform')"
    align-center>
    <div class="dialogbody">
      <el-form ref="refuserform" :model="userform" :rules="userformrules" label-width="120px">
        <el-form-item label="用户名" prop="userName" class="dialog-item">
          <el-input v-model="userform.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="surname" class="dialog-item">
          <el-input v-model="userform.surname"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phoneNumber" class="dialog-item">
          <el-input v-model="userform.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="emailAddress" class="dialog-item">
          <el-input v-model="userform.emailAddress"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" class="dialog-item" v-if="dialogtype == 'add'">
          <el-input v-model="userform.password"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="assignedRoleNames" class="dialog-item">
          <el-checkbox-group v-model="userform.assignedRoleNames" @change="handleCheckedrolesChange">
            <el-checkbox v-for="role in assignedRoleNamesoptions" :label="role.roleName"
              :key="role.roleId">{{ role.roledisplayName }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="激活" prop="isActive" class="dialog-item">
          <el-switch v-model="userform.isActive"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closedialog('refuserform')">取 消</el-button>
        <el-button type="primary" @click="submitForm('refuserform', dialogtype, userform.id)">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<!--lang="jsx" 下面就能用jsx语法-->
<script lang="jsx">
import topheader_components from '../layouts/topheader_components.vue'
import http from '../utill/http.js'
export default {
  components: {
    topheader_components
  },
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    return {
      dialogtitle: "新建用户",
      dialogtype: 'add',
      centerDialogVisible: false,
      table: {
        //firstColumn: { type: "selection", fixed: true },
        total: 0,
        currentPage: 1,
        pageSize: 10,
        layout: "total,sizes, slot, prev, pager, next, jumper",
        prevText: "上一页",
        nextText: "下一页",
        // 接口返回数据
        data: [],
        // 表头数据
        columns: [
          { prop: "userName", label: "用户名", width: 160, align: 'left', bind: { 'show-overflow-tooltip': false } },
          { prop: "fullName", label: "姓名", align: 'left', bind: { 'show-overflow-tooltip': false } },
          {
            prop: "roles", label: "角色", width: 300, bind: { 'show-overflow-tooltip': false }, render: (text) => {

              return (
                text.map(item => {
                  return (
                    <el-tag type="primay" style="margin-right:10px" effect="dark">{item.roleName}</el-tag>
                  )
                })
              )
            }
          },
          {
            prop: "isActive", label: "状态", width: 150, bind: { 'show-overflow-tooltip': false }, render: (text) => {
              // console.log(777, text, row, index)
              let type = '', val = ''
              switch (text) {
                case true:
                  type = 'success';
                  val = '激活';
                  break;
                default:
                  type = 'info';
                  val = '禁用';
                  break
              }
              const elem = <el-tag type={type} effect="dark">{val}</el-tag>; //jsx语法
              return (elem);
            }
          },
          { prop: "emailAddress", label: "邮箱", width: 300, bind: { 'show-overflow-tooltip': false } },
          { prop: "phoneNumber", label: "手机", width: 300, bind: { 'show-overflow-tooltip': false } },
          { prop: "creationTime", label: "创建时间", width: 300, bind: { 'show-overflow-tooltip': false } },
          {
            prop: "id", label: "操作", width: 200, bind: { 'show-overflow-tooltip': false }, 'render': (text, row) => {
              let strisActive = row.isActive == true ? '禁用' : "激活", btnstrisActivetype = row.isActive == true ? 'info' : "success";
              console.log(strisActive + "" + btnstrisActivetype);
              //let operation_arr=[{btntext:"编辑",btntype:"primary",operationtype:"edit"},{btntext:"删除",btntype:"danger",operationtype:"delete"},{btntext:strisActive,btntype:btnstrisActivetype,operationtype:"isactive"}];
              let operation_arr = [{ btntext: "编辑", btntype: "primary", operationtype: "edit" }, { btntext: "删除", btntype: "danger", operationtype: "delete" }];
              return (
                operation_arr.map(item => {
                  return (
                    //<el-button type="text"  onClick={() => this.operationHandler2(text,row)}>{item.btntext}</el-button>
                    <el-link type={item.btntype} style="margin-right:20px" onClick={() => this.operationHandler(text, item)}>{item.btntext}</el-link>
                  )
                })
              )

            }
          },
        ]
      },
      assignedRoleNamesoptions: [],//角色数据
      userform: {
        id: "",
        userName: "",
        surname: "",
        emailAddress: "",
        password: "",
        phoneNumber: "",
        isActive: true,
        assignedRoleNames: []

      },
      userformrules: {
        userName: [
          { required: true, message: '用户名必填', trigger: 'blur' },
        ],
        surname: [
          { required: true, message: '姓名必填', trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '手机号码必填', trigger: 'blur' },
          { validator: isMobileNumber, trigger: ['blur', 'change'] }
        ],
        emailAddress: [
          { required: true, message: '邮箱地址必填', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '密码必填', trigger: 'blur' },
        ],
        assignedRoleNames: [
          { required: true, message: '至少选择一个角色', trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
    this.getData();
    this.GetRolesList().then((res) => {
      if (res) {
        if (res.success == true) {
          if (res.result.items.length > 0) {
            var items_arr = res.result.items,
              assignedRoleNamesoptions_arr = [],
              isDefault_arr = [];

            for (var i = 0; i < items_arr.length; i++) {
              assignedRoleNamesoptions_arr.push(new Object({
                roleId: items_arr[i].id,
                roleName: items_arr[i].name,
                roledisplayName: items_arr[i].displayName
              }));
              if (items_arr[i].isDefault == true) {
                isDefault_arr.push(items_arr[i].name);
              }
            }
            this.assignedRoleNamesoptions = assignedRoleNamesoptions_arr;
            this.userform.assignedRoleNames = isDefault_arr;
          }
        }
      }
    })
  },
  methods: {
    //绑定数据到dom上
    getData() {
      this.GetUsers().then((res) => {
        if (res) {
          if (res.success == true) {
            this.table.data = res.result.items;
            this.table.total = res.result.items.length;
          }
        }
      });
    },
    //获取角色
    GetRolesList: async function () {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/Role/GetRolesList'
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    handleCheckedrolesChange(value) {
      this.userform.assignedRoleNames = value;
    },
    //获取用户
    GetUsers: async function () {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/User/GetUsers'
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    addUser: function () {
      this.dialogtitle = "新建用户";
      this.dialogtype = "add",
      this.centerDialogVisible = true;
    },
    operationHandler: function (id, item) {
      var operationtype = item.operationtype;
      if (operationtype == "edit") {
        this.dialogtitle = "编辑用户";
        this.dialogtype = "edit",
          this.editUser(id);
      }
      else if (operationtype == "delete") {
        this.deleteUser(id, item);
      }
      else if (operationtype == "isactive") {
        this.isactiveHandler(id, item);
      }
    },

    editUser: function (id) {
      this.centerDialogVisible = true;
      var defaultassignedRoleNames_arr = [];
      var data = this.table.data.filter(tb => tb.id == id);

      if (data.length > 0 && data.length == 1) {
        this.userform = data[0];
        //角色数据
        for (var i = 0; i < data[0].roles.length; i++) {
          defaultassignedRoleNames_arr.push(this.assignedRoleNamesoptions.filter(ar => ar.roleId == data[0].roles[i].roleId)[0].roleName);
        }
      }
      this.userform.assignedRoleNames = defaultassignedRoleNames_arr;
    },
    closedialog(formName) {
      this.centerDialogVisible = false;
      this.userform = {
        id: "",
        userName: "",
        surname: "",
        emailAddress: "",
        password: "",
        phoneNumber: "",
        isActive: true,
        assignedRoleNames: []

      };
      this.$refs[formName].resetFields();

    },
    submitForm(formName, type, id) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var strmessage = '新建', param = {
            id: this.userform.id,
            userName: this.userform.userName,
            surname: this.userform.surname,
            emailAddress: this.userform.emailAddress,
            password: this.userform.password,
            phoneNumber: this.userform.phoneNumber,
            isActive: this.userform.isActive,
            assignedRoleNames: this.userform.assignedRoleNames,

          };

          if (type == "edit") {
            strmessage = "编辑";
            param.id = id;
          }
          this.CreateOrUpdateUser(param).then((res) => {
            if (res) {
              if (res.success == true) {
                this.$message.success(strmessage + '成功');
                this.closedialog(formName);
                this.getData();
              }
              else {
                this.$message.error(strmessage + '失败');
              }
            }
            else {
              this.$message.error(strmessage + '失败');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    CreateOrUpdateUser: async function (param) {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/User/CreateOrUpdateUser',
          data: param
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    //删除用户
    deleteUser: function (id, item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("deleteUser:" + id);
        this.Delete(id).then((res) => {
          if (res) {
            if (res.success == true) {
              this.$message.success('删除成功');
              this.getData();
            }
            else {
              this.$message.error('删除失败');
            }
          }
          else {
            this.$message.error('删除失败');
          }
        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消' + item.btntext
        });
      });
    },
    Delete: async function (id) {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/User/Delete',
          data: { "Id": id }
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    //激活和禁用
    isactiveHandler: function (id, item) {
      this.$confirm('确认' + item.btntext + '吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("isactiveHandler:" + id);
        this.$message({
          type: 'success',
          message: item.btntext + '成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消' + item.btntext
        });
      });

    },
    linkto(linkpage) {
      this.$router.push({ path: '/' + linkpage });
    }
  }
}
</script>

<style>
.dialog-item {
  margin-bottom: 30px;
}

#top-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
}

.top_tabs_title {
  width: 156px;
  height: 50px;
  background-image: url('../../public/images/tabbg.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: YouSheBiaoTiHei;
  font-size: 24px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  line-height: 50px;
  font-style: normal;
  margin: 0 10px;
  cursor: pointer
}

.top_tabs_title.istabactive {
  background-image: url('../../public/images/tabbg-selected.png');
}

.t_layout_page_item,
.el-popper.is-light {
  background: #191E25 !important;
}

.t-table,
.el-table tr,
.el-table--large,
.el-pagination,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}

.el-table .el-table__cell {
  border-color: #37383B !important;
}

.el-table th.el-table__cell {
  background-color: #22262D !important;
  border-color: #37383B !important;
}

.header_title {
  font-size: 24px !important;
  color: #fff !important;
}

.el-table--large .cell {
  font-size: 20px !important;
}

.el-table--large .el-tag {
  font-size: 18px !important;
}

.el-table--border .el-table__inner-wrapper:after,
.el-table--border:after,
.el-table--border:before,
.el-table__inner-wrapper:before {
  background-color: transparent !important
}

.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell,
.el-select-dropdown__item.is-hovering {
  background-color: #22262D !important;
}

.el-select--large .el-select__wrapper,
.el-input__wrapper,
.el-pagination.is-background .btn-next.is-disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.is-disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.is-disabled,
.el-pagination.is-background .el-pager li:disabled {
  font-size: 18px !important;
  border: 1px solid #37383B !important;
  margin: 0 6px 0 0 !important;
  height: 2.5em !important;
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.45) !important;
}

.el-pagination>.is-first,
.el-pagination__goto,
.el-pagination__classifier,
.el-link {
  font-size: 18px !important;

}

.el-input__wrapper {
  box-shadow: none !important;
}

.el-popper.is-light,
.el-popper.is-light .el-popper__arrow:before {
  border-color: #191E25 !important;
  background: #191E25 !important;
}
</style>
