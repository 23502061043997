import { createRouter,createWebHashHistory} from 'vue-router'
import loginView from '../views/loginView.vue'
import homeView from '../views/homeView.vue'
import airstationView from '../views/airstationView.vue'
import dataconfigView from '../views/dataconfigView.vue'
import adminView from '../views/adminView.vue'
import chartconfigView from '../views/chartconfigView.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: loginView,
    meta: { title: '登录' }
  },
  {
    path: '/home',
    name: 'home',
    component: homeView,
    meta: { title: '能耗监测' }
  },
  {
    path: '/airstation',
    name: 'airstation',
    component: airstationView,
    meta: { title: '空压站' }
  },
  {
    path: '/dataconfig',
    name: 'dataconfig',
    component:dataconfigView,
    meta: { title: '自定义图表' }
  },
  {
    path: '/admin',
    name: 'admin',
    component: adminView,
    meta: { title: '用户管理' }
  },
  {
    path: '/chartconfig',
    name: 'chartconfig',
    component: chartconfigView,
    meta: { title: '图表配置' }
  }
  
  
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title || '默认标题'
  next()
})
export default router
