<template>
   <div id="footer_container">

   </div>

</template>

<script>

export default {
  
  data() {
    return {
     
    }
  },
  methods: {
  }
}
</script>

<style scoped>
#footer_container{
  position: absolute;
  width: 100%;
  height: 21.2px;
  bottom: 26px;
  background-image: url('../../public/images/foot-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>