<template>
  <topheader_components></topheader_components>
  <div id="page-body">
    <div id="layout_left">
      <div id="layout_left_top">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[0] ? this.chartdata[0].title : "" }}</span>
          </div>
          <div class="chart_panel_body">
            <div class="nyzxh_container">
              <div class="nyzxh_item" v-for="(item, index) in nyzxhInfodata" :key="index">
                <img :src="item.nyzxh_item_img" style="width: 54px;height: 54px;" />
                <div class="nyzxh_item_data">
                  <div class="nyzxh_item_value_data"><span class="nyzxh_item_value">{{ item.nyzxh_item_value
                      }}</span><span class="nyzxh_item_unit">{{ item.nyzxh_item_unit }}</span></div>
                  <div class="nyzxh_item_title">{{ item.nyzxh_item_title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="layout_left_center">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[1] ? this.chartdata[1].title : "" }}</span>
            <span class="chart_panel_title_toolbar">
              单位：{{ this.chartdata[1] ? this.chartdata[1].unit : "" }}
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe" v-if="this.chartdata[1] ? this.chartdata[1].gFpath : false">
              <iframe :src="this.chartdata[1] ? this.chartdata[1].gFpath : null" class="chart_iframe"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址"></el-empty>
          </div>
        </div>
      </div>
      <div id="layout_left_bottom">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[2] ? this.chartdata[2].title : "" }}</span>
            <span class="chart_panel_title_toolbar">
              单位：{{ this.chartdata[2] ? this.chartdata[2].unit : "" }}
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe" v-if="this.chartdata[2] ? this.chartdata[2].gFpath : false">
              <iframe :src="this.chartdata[2] ? this.chartdata[2].gFpath : null" class="chart_iframe"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div id="layout_center">
      <div id="layout_center_top">
        <div class="img-content" style="position: relative;">
          <img class="img" src="../../public/images/主图.png" style="height:832px" border="0" usemap="#Map" />
          <map name="Map">
            <area v-for="item in infosuspensiondata" :key="item.title" shape="rect" :coords="item.coords"
              @mouseover="showinfosuspension(item)" @mouseout="isinfopanelshow = false" style="cursor: pointer;" />
          </map>
          <div class="infopanel" v-if="isinfopanelshow" :style="infopanelpostion">
            <div class="infopanelheader">
              <div class="infopanelheader_text">{{ currentinfopanel.title }}</div>
            </div>
            <div class="infopanelbody">
              <div class="infovalue">
                <div class="infovalue_value bgcolor1">{{ currentinfopanel.data.dqNum }}</div>
                <div class="infovalue_title">氮气<span class="infovalue_unit">(m³)</span></div>
              </div>
              <div class="v-line"></div>
              <div class="infovalue">
                <div class="infovalue_value bgcolor2">{{ currentinfopanel.data.trqNum }}</div>
                <div class="infovalue_title">天然气<span class="infovalue_unit">(m³)</span></div>
              </div>
              <div class="v-line"></div>
              <div class="infovalue">
                <div class="infovalue_value bgcolor3">{{ currentinfopanel.data.dNum }}</div>
                <div class="infovalue_title">电<span class="infovalue_unit">(kwh)</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="layout_center_bottom">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[3] ? this.chartdata[3].title : "" }}</span>
            <span class="chart_panel_title_toolbar" v-if="this.chartdata[3] ? this.chartdata[3].gFpath : false">
              <div class="chartdatalegend">
                <div class="chartdatalegend-item" v-for="(item, index) in chartdatalegend" :key="index">
                  <div class="chartdatalegend-item-color" :style="{ 'background-color': item.legendcolor }"></div>
                  <div class="chartdatalegend-item-text">{{ item.legendtext }}</div>
                </div>
              </div>
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe layout_center_bottom_iframe"
              v-if="this.chartdata[3] ? this.chartdata[3].gFpath : false">
              <iframe :src="this.chartdata[3] ? this.chartdata[3].gFpath : null" class="chart_iframe mt-0"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址" :image-size="140"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div id="layout_right">
      <div id="layout_right_top">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[4] ? this.chartdata[4].title : "" }}</span>
            <span class="chart_panel_title_toolbar">
              单位：{{ this.chartdata[4] ? this.chartdata[4].unit : "" }}
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe" v-if="this.chartdata[4] ? this.chartdata[4].gFpath : false">
              <iframe :src="this.chartdata[4] ? this.chartdata[4].gFpath : null" class="chart_iframe"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址"></el-empty>
          </div>
        </div>
      </div>
      <div id="layout_right_center">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[5] ? this.chartdata[5].title : "" }}</span>
            <span class="chart_panel_title_toolbar">
              单位：{{ this.chartdata[5] ? this.chartdata[5].unit : "" }}
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe" v-if="this.chartdata[5] ? this.chartdata[5].gFpath : false">
              <iframe :src="this.chartdata[5] ? this.chartdata[5].gFpath : null" class="chart_iframe"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址"></el-empty>
          </div>
        </div>
      </div>
      <div id="layout_right_bottom">
        <div class="chart_panel">
          <div class="chart_panel_title">
            <span class="chart_panel_title_text">{{ this.chartdata[6] ? this.chartdata[6].title : "" }}</span>
            <span class="chart_panel_title_toolbar">
              单位：{{ this.chartdata[6] ? this.chartdata[6].unit : "" }}
            </span>
          </div>
          <div class="chart_panel_body">
            <div class="chart_panel_body_iframe" v-if="this.chartdata[6] ? this.chartdata[6].gFpath : false">
              <iframe :src="this.chartdata[6] ? this.chartdata[6].gFpath:null" class="chart_iframe"></iframe>
            </div>
            <el-empty v-else description="暂无gFpath地址"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer_components></footer_components>
</template>

<script>
import topheader_components from '../layouts/topheader_components.vue'
import footer_components from '../layouts/footer_components.vue'
import http from '../utill/http.js'
export default {
  components: {
    topheader_components,
    footer_components
  },
  data() {
    return {
      chartdata: [],
      centerDialogVisible: false,
      nyzxhInfodata: [
        { "key": "ljydl", "nyzxh_item_img": '../images/ljydl.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "KWH", "nyzxh_item_title": "累计用电量" },
        { "key": "ljysl", "nyzxh_item_img": '../images/ljysl.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "T", "nyzxh_item_title": "累计用水量" },
        { "key": "ljtrql", "nyzxh_item_img": '../images/ljtrql.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "m³", "nyzxh_item_title": "累计天然气量" },
        { "key": "ljdql", "nyzxh_item_img": '../images/ljdql.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "m³", "nyzxh_item_title": "累计氮气量" },
        { "key": "ljyskql", "nyzxh_item_img": '../images/ljyskql.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "m³", "nyzxh_item_title": "累计压缩空气量" },
        { "key": "ktljydtj", "nyzxh_item_img": '../images/ktljydtj.png', "nyzxh_item_value": "0", "nyzxh_item_unit": "KWH", "nyzxh_item_title": "空调累计用电统计" },
      ],
      chartconfigform: {
        "id": "",
        "type": "NHJC",
        "title": "",
        "position": "",//位置
        "gFpath": ""//iframe地址

      },
      isinfopanelshow: false,
      currentinfopanel: { title: "", data: { "dqNum": 0, "trqNum": 0, "dNum": 0 } },
      infopanelpostion: { top: "0px", left: "0px" },
      infosuspensiondata: [
        { title: "钎焊炉101", position: '41px,682px', coords: '844,214,861,300', id: "101" },
        { title: "钎焊炉201", position: '440px,345px', coords: '495,340,508,430', id: "201" },
        { title: "钎焊炉301", position: '41px,345px', coords: '505,218,517,312', id: "301" },
        { title: "钎焊炉401", position: '41px,490px', coords: '615,213,631,303', id: "401" },
        { title: "钎焊炉501", position: '440px,490px', coords: '612,339,629,427', id: "501" },
        { title: "钎焊炉601", position: '438px,568px', coords: '723,280,751,430', id: "601" },
        { title: "钎焊炉701", position: '240px,940px', coords: '903,313,937,464', id: "701" }
      ],
      chartdatalegend: [{ legendcolor: "rgba(87, 148, 242, 0.5)", legendtext: "氮气（m³）" },
      { legendcolor: "rgba(192, 216, 255, 0.5)", legendtext: "天然气（m³ ）" },
      { legendcolor: "rgba(255, 120, 10, 0.5)", legendtext: "电（kwh）" }],
    }
  },
  mounted() {
    this.GetPageContentByType().then((res) => {
      if (res) {
        if (res.success == true) {
          this.chartdata = res.result;
        }
      }

    });

    this.GetNYZXHInfohtml();
    setInterval(() => {
      console.log("每隔5分钟执行一次");
      this.GetNYZXHInfohtml();
    }, 300000);


  },
  methods: {
    GetPageContentByType: async function () {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/FLADP/GetPageContentByType',
          data: { "type": "NHJC" }
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    chartconfig(chartitem) {
      this.centerDialogVisible = true,
        this.chartconfigform = chartitem;
    },
    closedialog(formName) {
      this.centerDialogVisible = false;
      this.chartconfigform = {
        "id": "",
        "type": "NHJC",
        "title": "",
        "position": "",//位置
        "gFpath": ""//iframe地址
      };
      console.log(formName);
      //this.$refs[formName].resetFields();

    },
    GetNYZXHInfohtml() {
      this.GetNYZXHInfo().then((res) => {
        if (res) {
          if (res.success == true) {
            let data = res.result, NYZXHInfodata_arr = this.nyzxhInfodata;
            for (let i = 0; i < NYZXHInfodata_arr.length; i++) {
              NYZXHInfodata_arr[i]["nyzxh_item_value"] = data[NYZXHInfodata_arr[i]["key"]];
            }
            this.NYZXHInfodata = NYZXHInfodata_arr;
          }
        }
      });

    },
    GetNYZXHInfo: async function () {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/FLADP/GetNYZXHInfo'
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
    showinfosuspension(item) {
      console.log("showinfosuspensionshowinfosuspensionshowinfosuspensionshowinfosuspensionshowinfosuspension")
      this.GetQHLNHInfo(item.id).then((res) => {
        if (res) {
          if (res.success == true) {
            this.isinfopanelshow = true;
            this.currentinfopanel.title = item.title;
            this.currentinfopanel.data = res.result;
            let strpostion_data = item.position.split(",")
            this.infopanelpostion.top = strpostion_data[0];
            this.infopanelpostion.left = strpostion_data[1];
            console.log("showinfosuspension:" + item.postion);
          }
          else {
            this.currentinfopanel.data = { "dqNum": 0, "trqNum": 0, "dNum": 0 };
          }
        }
      }).catch(() => { this.currentinfopanel.data = { "dqNum": 0, "trqNum": 0, "dNum": 0 }; });
    },
    GetQHLNHInfo: async function (dataid) {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/FLADP/GetQHLNHInfo',
          data: { id: dataid }
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    }

  }
}
</script>

<style scoped>
#layout_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1152.35px;
  width: 533.33px;
  background-image: url('../../public/images/L.png');

  #layout_left_top {
    width: 470px;
    height: 333.33px;
    margin-top: 27px;
    margin-bottom: 75px;

  }

  #layout_left_center {
    width: 470px;
    height: 300px;

    margin-bottom: 65px;

  }

  #layout_left_bottom {
    width: 470px;
    height: 300px;

    margin-bottom: 53px;
  }
}

#layout_center {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  #layout_center_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  #layout_center_bottom {

    width: 1342.67px;
    height: 298.67px;
    background-image: url('../../public/images/C_B.png');
  }
}

#layout_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../public/images/R.png');
  height: 1152.35px;
  width: 533.33px;

  #layout_right_top {
    width: 470px;
    height: 300px;
    margin-top: 27px;
    margin-bottom: 65px;

  }

  #layout_right_center {
    width: 470px;
    height: 300px;
    margin-bottom: 65px;

  }

  #layout_right_bottom {
    width: 470px;
    height: 300px;
    margin-bottom: 53px;
  }
}

.chart_panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .chart_panel_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 58px;
    background-image: url('../../public/images/标题bg.png');
    background-repeat: no-repeat;
    background-size: contain;

    .chart_panel_title_text {
      width: auto;
      height: 45px;
      font-family: YouSheBiaoTiHei;
      font-size: 32px;
      color: #FFFFFF;
      line-height: 45px;
      text-align: left;
      font-style: normal;
      margin-left: 46px;
    }

    .chart_panel_title_toolbar {
      width: auto;
      height: 45px;
      font-family: HarmonyOS_Sans_SC;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 45px;
      text-align: center;
      font-style: normal;
    }
  }

  .chart_panel_body {
    height: calc(100% - 65px);

    .chart_panel_body_iframe {
      width: 100%;
      height: 100%;

      /* transform: scale(0.46);
transform-origin: top left; */
      .chart_iframe {
        /* width:1000px;
  height: 580px; */
        width: 100%;
        height: 100%;
        border: none;
        margin-top: 32px;
      }
    }

    .layout_center_bottom_iframe {
      width: 100%;
      height: 100%;
      transform: none !important;
      transform-origin: initial !important;

      .chart_iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }

  }

}

.nyzxh_container {
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
}

.nyzxh_item {
  width: calc(100% / 2);
  display: flex;
  margin-bottom: 32px;

  .nyzxh_item_data {
    padding-left: 13px;
  }

  .nyzxh_item_value {
    width: 68px;
    height: 37px;
    font-family: HarmonyOS_Sans_SC_Bold;
    font-size: 27px;
    color: #FFFFFF;
    line-height: 37px;
    text-align: left;
    font-style: normal;
    padding-right: 10px;
  }

  .nyzxh_item_title {
    width: auto;
    height: 26px;
    font-family: HarmonyOS_Sans_SC;
    font-size: 19px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 26px;
    text-align: left;
    font-style: normal;
  }

  .nyzxh_item_unit {
    width: 48px;
    height: 26px;
    font-family: HarmonyOS_Sans_SC;
    font-size: 19px;
    color: rgba(255, 255, 255, 0.45);
    line-height: 26px;
    text-align: right;
    font-style: normal;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.infopanel {
  position: absolute;
  top: 0;
  left: 461px;
  width: 331px;
  height: 171px;
  background: rgba(26, 33, 50, 0.85);
  border: 1px solid #5885B2;

  .infopanelheader {
    width: 288px;
    height: 32px;
    margin: 13px 22px;
    background-image: url('../../public/images/card.png');
    background-repeat: no-repeat;
    background-size: contain;

    .infopanelheader_text {
      width: auto;
      height: 24px;
      font-family: HarmonyOS_Sans_SC_Bold;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      font-style: normal;
    }
  }

  .infopanelbody {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 82px);

    .infovalue {
      height: 57px;
      margin: 0 10px;

      .infovalue_value {
        height: 24px;
        font-family: D-DIN, D-DIN;
        font-weight: bold;
        font-size: 32px;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        margin-bottom: 13px;
      }

      .bgcolor1 {
        color: #4BD6F7FF;
      }

      .bgcolor2 {
        color: #FFFFFFFF;
      }

      .bgcolor3 {
        color: #FA9602D9;
      }

      .infovalue_title {

        height: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 19px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 26px;
        text-align: center;
        font-style: normal;

        .infovalue_unit {
          width: 100px;
          height: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.45);
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }

      }

    }

    .v-line {
      width: 0px;
      height: 24px;
      border: 1px solid rgba(255, 255, 255, 0.25);
    }
  }
}

.chartdatalegend {
  display: flex;

  .chartdatalegend-item {
    margin: 0 10px;
    display: flex;
    align-items: center;

    .chartdatalegend-item-color {
      width: 16px;
      height: 11px;
      margin-right: 10px;
    }
  }
}
</style>
