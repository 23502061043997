<template>
  <div class="earth-menu">
    <div class="menu-contaier">
      <div class="menu-contaier-bg"></div>
      <div class="menu menu1" :title="menu1title">
        <router-link :to="link1name"><img :src="menu1img" /></router-link>
      </div>
      <div class="menu menu2" :title="menu2title">
        <router-link :to="link2name"><img :src="menu2img" /></router-link>
      </div>
      <div class="menu menu3" :title="menu3title">
        <router-link :to="link3name"><img :src="menu3img" /></router-link>
      </div>
      <div class="menu menu4" :title="menu4title">
        <router-link :to="link4name"><img :src="menu4img" /></router-link>
      </div>
    </div>
  </div>
  <div id="topheader_container">
    <span id="topheader_title_left"></span>
    <span id="topheader_title">{{ $ProjectPlatformName }}</span>
    <div id="topheader_title_right">
      <span id="topheader_weather_date">
        <span id="topheader_weather">
          <img src="../../public/images/weather/Fill1.png" style="width: inherit;height: inherit;" />
        </span>
        <span id="topheader_datetime">
          <span id="topheader_date">
            <span id="topheader_date_date">{{ dateDay }}</span>
            <span id="topheader_date_week">{{ dateWeek }} <span id="topheader_date_weather">{{ weathertext
                }}</span></span>
          </span>
          <span id="topheader_time">{{ dateTime }}</span>
        </span>
      </span>
      <span id="topheader_line-v"></span>
      <span id="topheader_account" :title="nickName">
        <img id="topheader_account_img" src="../../public/images/管理员.png" />
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ nickName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template v-slot:dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="openupdatepwddialog">修改密码</el-dropdown-item>
              <el-dropdown-item @click="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>
    </div>

    <el-dialog v-model="updatepwdDialogVisible" :title="updatepwddialogtitle" width="400"
      close="closeupdatepwddialog('refupdatepwdform')" align-center>
      <div class="dialogbody">
        <el-form ref="refupdatepwdform" :model="updatepwdform" :rules="updatepwdformrules" label-width="120px">
          <el-form-item label="用户名" prop="userName" class="dialog-item" >
            <el-input v-model="updatepwdform.userName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="旧密码" prop="oldpassword" class="dialog-item">
            <el-input v-model="updatepwdform.oldpassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newpassword" class="dialog-item">
            <el-input v-model="updatepwdform.newpassword"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeupdatepwddialog('refupdatepwdform')">取 消</el-button>
          <el-button type="primary" @click="submitupdatepwdForm('refupdatepwdform', updatepwdform.id)">确 定</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import http from '../utill/http.js'
export default {
  data() {
    // var validatePass2 = (rule, value, callback) => {
    //     if (value === '') {
    //       callback(new Error('请再次输入密码'));
    //     } else if (value !== this.updatepwdform.oldpassword) {
    //       callback(new Error('两次输入密码不一致!'));
    //     } else {
    //       callback();
    //     }
    //   };
    return {
      menu1title: "能耗监测",
      menu1img: require('../../public/images/menu1.png'),
      link1name: '/home',
      menu2title: "空压站",
      menu2img: require('../../public/images/menu2.png'),
      link2name: '/airstation',
      menu3title: "自定义图表",
      menu3img: require('../../public/images/menu3.png'),
      link3name: '/dataconfig',
      menu4title: "系统设置",
      menu4img: require('../../public/images/menu4.png'),
      link4name: '/admin',
      dateDay: "",
      dateWeek: '',
      weathertext: '晴',
      userName: "",
      nickName: 'Admin',
      dateTime: "",
      timer: '',
      updatepwdDialogVisible: false,
      updatepwddialogtitle: "修改密码",
      updatepwdform: {
        userName: "",
        oldpassword: "",
        newpassword: ""

      },
      updatepwdformrules: {
        userName: [
          { required: true, message: '用户名必填', trigger: 'blur' },
        ],
        oldpassword: [
          { required: true, message: '旧密码必填', trigger: 'blur' },
        ],
        newpassword: [
          { required: true, message: '新密码必填', trigger: 'blur' }
          // { validator: validatePass2, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    GetDate() {
      var date = new Date();
      var Y = date.getFullYear() + '.';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      var a = new Array("日", "一", "二", "三", "四", "五", "六");
      var week = date.getDay();
      this.dateDay = Y + M + D;
      this.dateWeek = "星期" + a[week];
      this.dateTime = h + m + s;
    },
    loginout() {
      window.sessionStorage.clear();
      this.$router.push({ path: '/' });
    },
    openupdatepwddialog() {
      this.updatepwdDialogVisible = true;
    },
    closeupdatepwddialog(formName) {
      this.updatepwdDialogVisible = false;
      this.updatepwdform = {
        userName:this.nickName,
        oldpassword: "",
        newpassword: ""
      };
      this.$refs[formName].resetFields();

    },
    submitupdatepwdForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var strmessage = '修改密码', param = {
            currentPassword: this.updatepwdform.oldpassword,
            newPassword: this.updatepwdform.newpassword
          };

          this.ChangePassword(param).then((res) => {
            if (res) {
              if (res.success == true) {
                this.$message.success(strmessage + '成功');
              }
              else {
                this.$message.error(strmessage + '失败');
              }
            }
            else {
              this.$message.error(strmessage + '失败');
            }
            this.closeupdatepwddialog(formName);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    ChangePassword: async function (param) {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/User/ChangePassword',
          data: param
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    }


  },
  mounted() {
    console.log("nickName:" + window.sessionStorage.getItem("nickName"))
    if (window.sessionStorage.getItem("nickName") != null) {
      if (this.$route.path == "/home") {
        this.menu1img = require('../../public/images/menu1-selected.png');

        this.menu2img = require('../../public/images/menu2.png');
        this.menu3img = require('../../public/images/menu3.png');
        this.menu4img = require('../../public/images/menu4.png');
      }
      if (this.$route.path == "/airstation") {
        this.menu2img = require('../../public/images/menu2-selected.png');

        this.menu1img = require('../../public/images/menu1.png');
        this.menu3img = require('../../public/images/menu3.png');
        this.menu4img = require('../../public/images/menu4.png');
      }
      if (this.$route.path == "/dataconfig") {
        this.menu3img = require('../../public/images/menu3-selected.png');

        this.menu1img = require('../../public/images/menu1.png');
        this.menu2img = require('../../public/images/menu2.png');
        this.menu4img = require('../../public/images/menu4.png');
      }

      if (this.$route.path == "/admin" || this.$route.path == "/chartconfig") {
        this.menu4img = require('../../public/images/menu4-selected.png');

        this.menu1img = require('../../public/images/menu1.png');
        this.menu2img = require('../../public/images/menu2.png');
        this.menu3img = require('../../public/images/menu3.png');
      }

      this.nickName = window.sessionStorage.getItem("nickName");
      this.updatepwdform.userName=this.nickName;
      this.timer = setInterval(() => {
        this.GetDate();
      }, 1000);
    }
    else { this.$router.push({ path: '/' }); }
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

}
</script>

<style scoped>
#topheader_container {
  position: absolute;
  top: 13.33px;
  left: 241.33px;
  width: 2305.33px;
  height: 101.33px;
  background-image: url('../../public/images/Headbg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

#topheader_title_left {
  width: 600px;
  height: inherit;
}

#topheader_title {
  flex: auto;
  height: inherit;
  font-family: YouSheBiaoTiHei;
  font-size: 53px;
  color: #FFFFFF;
  line-height: 75px;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;
}

#topheader_title_right {
  position: relative;
  width: 810px;
  height: inherit;
}

#topheader_weather_date {
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  right: 192px;
  width: 291.81px;
}

#topheader_weather {
  display: inline-block;
  width: 29.33px;
  height: 29.33px;
}

#topheader_datetime {
  display: flex;
  align-items: center;
}

#topheader_time {
  width: 153px;
  height: 45px;
  font-family: DINAlternate, DINAlternate;
  font-weight: 400;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 45px;
  text-align: left;
  font-style: normal;
  letter-spacing: 2px;
}

#topheader_date {
  display: inline-block;
  width: 84px;
  margin-right: 12px;
  margin-left: 14px;
}

#topheader_date_week {
  width: 48px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  text-align: right;
  font-style: normal;
}

#topheader_date_weather {
  margin-left: 12px;

}

#topheader_date_date {
  width: 48px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  text-align: right;
  font-style: normal;
}

#topheader_line-v {
  position: absolute;
  top: 20px;
  right: 163.72px;
  width: 1px;
  height: 25.33px;
  background-color: #FFFFFF66
}

#topheader_account {
  position: absolute;
  top: 18px;
  right: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#topheader_account_img {
  width: 32px;
  height: 32px;
}

.el-dropdown {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 65px !important;
  height: 26px !important;
  font-family: DINAlternate, DINAlternate !important;
  font-size: 19px !important;
  color: #FFFFFF !important;
  line-height: 26px !important;
  text-align: left !important;
  font-style: normal !important;
}

.el-dropdown-menu {
  padding: 1px !important;
}

.earth-menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 218.67px;
  height: 176px;
  background-image: url('../../public/images/nav_bg.png');
}

.menu-contaier {
  position: relative;

  .menu {
    position: absolute;
    cursor: pointer;
    width: 32px;
    height: 32px;
  }

  .menu1 {
    left: 178.67px;
    top: 24px;
  }

  .menu2 {
    left: 141.33px;
    top: 81.33px;
  }

  .menu3 {
    left: 88.67px;
    top: 114.04px;
  }

  .menu4 {
    left: 23px;
    top: 124.67px;
  }
}
</style>