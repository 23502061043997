<template>
    <topheader_components></topheader_components>
    <div id="page-body">
      <iframe src="https://valeo.axendo.cn:3001/d/fdwypz39f4mwwd/e6b395-e99bb7-e5a5a5-e695b0-e68dae-e99da2-e69dbf?orgId=1&kiosk" style="border: 0;width:calc(100% - 60px) ;height: 1152.35px;"></iframe>
    </div>
    <footer_components></footer_components>
</template>

<script>
import topheader_components from '../layouts/topheader_components.vue'
import footer_components from '../layouts/footer_components.vue'
export default {
  components: {
    topheader_components,
    footer_components
  },
  data() {
      return {
        
      };
    },
    methods: {
     
    }
}
</script>

<style scoped>
.chart_panel{
  height: 100%;
  display: flex;
    flex-direction: column;
    padding: 10px;
.chart_panel_title{
  display: flex;
    justify-content: space-between;
width: 100%;
height: 58px;
background-image: url('../../public/images/标题bg.png');
background-repeat: no-repeat;
background-size: contain;
.chart_panel_title_text{
  width: auto;
height: 45px;
font-family: YouSheBiaoTiHei;
font-size: 32px;
color: #FFFFFF;
line-height: 45px;
text-align: left;
font-style: normal;
margin-left: 46px;
}
.chart_panel_title_toolbar{
  width: auto;
height: 24px;
font-family: HarmonyOS_Sans_SC;
font-size: 16px;
color: rgba(255,255,255,0.65);
line-height: 24px;
text-align: center;
font-style: normal;
}
}
.chart_panel_body{
  height: calc(100% - 75px);
}

}
.chart_iframe{
  width:1000px;
  height: 638.29px;
  border: none;
  margin-top:32px;
}
</style>
