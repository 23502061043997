<template>
  <topheader_components></topheader_components>
  <div id="page-body">
    <div id="airstation_container" style="width:2241.33px;height: 1082.67px;position: relative;">
      <img src="../../public/images/空压站主图.png" style="width:100%;height: 100%;" />
      <div id="thumbnail_img_container">
        <div class="thumbnail_img" :class="airstationdata.acfS1_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.acfS1==8}">1#</div>
        </div>
        <div class="thumbnail_img" :class="airstationdata.acfS2_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.acfS2==8}">2#</div>
        </div>
        <div class="thumbnail_img" :class="airstationdata.acfS3_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.acfS3==8}">3#</div>
        </div>
        <div class="thumbnail_img" :class="airstationdata.acfS4_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.acfS4==8}">4#</div>
        </div>
        <div class="thumbnail_img" :class="airstationdata.acfS5_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.acfS5==8}">5#</div>
        </div>
      </div>
      <div id="thumbnail_img_container_data">
        <div class="thumbnail_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.acrS1_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acP1}}</span><span class="data_container_data_data_unit">Bar</span>
            </div>
          </div>
          <div class="thumbnail_img_container_data_container_data">
            <div
              class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.acfS1_blockcls">
              {{airstationdata.acfS1_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acT1}}</span><span class="data_container_data_data_unit">°C</span>
            </div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.acrS2_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acP2}}</span><span class="data_container_data_data_unit">Bar</span>
            </div>
          </div>
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.acfS2_blockcls">{{airstationdata.acfS2_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acT2}}</span><span class="data_container_data_data_unit">°C</span>
            </div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.acrS3_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acP3}}</span><span class="data_container_data_data_unit">Bar</span>
            </div>
          </div>
          <div class="thumbnail_img_container_data_container_data">
            <div
              class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.acfS3_blockcls">
              {{airstationdata.acfS3_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acT3}}</span><span class="data_container_data_data_unit">°C</span>
            </div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.acrS4_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acP4}}</span><span class="data_container_data_data_unit">Bar</span>
            </div>
          </div>
          <div class="thumbnail_img_container_data_container_data">
            <div
              class="thumbnail_img_container_data_container_data_title_tag"  :class="airstationdata.acfS4_blockcls">
              {{airstationdata.acfS4_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acT4}}</span><span class="data_container_data_data_unit">°C</span>
            </div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.acrS5_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acP5}}</span><span class="data_container_data_data_unit">Bar</span>
            </div>
          </div>
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.acfS5_blockcls">{{airstationdata.acfS5_name}}</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{airstationdata.acT5}}</span><span class="data_container_data_data_unit">°C</span>
            </div>
          </div>
        </div>
      </div>
      <div id="zylg_container">
        <span class="data_container_data_data_value">{{ airstationdata.mp }}</span><span
          class="data_container_data_data_unit">Bar</span>
      </div>
      <div id="thumbnail_s_img_container">
        <div class="thumbnail_img_s" :class="airstationdata.dfS1_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.dfS1==7}">1#</div>
        </div>
        <div class="thumbnail_img_s" :class="airstationdata.dfS2_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.dfS2==7}">2#</div>
        </div>
        <div class="thumbnail_img_s" :class="airstationdata.dfS3_cls">
          <div class="tag_title" :clas="{'tag_title_red':airstationdata.dfS3==7}">3#</div>
        </div>
      </div>
      <div id="thumbnail_img_container_data" class="thumbnail_s_img_container_data">
        <div class="thumbnail_img_container_data_container thumbnail_s_img_container_data_container" >
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.drS1_name}}</div>
            <div
              class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.dfS1_blockcls">
              {{airstationdata.dfS1_name}}</div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container thumbnail_s_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.drS2_name}}</div>
            <div
              class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.dfS2_blockcls">
              {{airstationdata.dfS2_name}}</div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container thumbnail_s_img_container_data_container">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">{{airstationdata.drS3_name}}</div>
            <div
              class="thumbnail_img_container_data_container_data_title_tag" :class="airstationdata.dfS3_blockcls">
              {{airstationdata.dfS3_name}}</div>
          </div>
        </div>
      </div>
      <div id="ld_data_container">
        <div class="thumbnail_img_container_data_container thumbnail_img_container_data_container_ld">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">露点1</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{ airstationdata.dpT1 }}</span><span
                class="data_container_data_data_unit">℃Td</span></div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container thumbnail_img_container_data_container_ld">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_title">露点2</div>
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{ airstationdata.dpT2 }}</span><span
                class="data_container_data_data_unit">℃Td</span>
            </div>
          </div>
        </div>
      </div>
      <div id="xsll_data_container">
        <div class="thumbnail_img_container_data_container thumbnail_img_container_data_container_xsll">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{ airstationdata.iF1 }}</span><span
                class="data_container_data_data_unit">M³/h</span></div>
          </div>
        </div>
        <div class="thumbnail_img_container_data_container thumbnail_img_container_data_container_xsll">
          <div class="thumbnail_img_container_data_container_data">
            <div class="thumbnail_img_container_data_container_data_data"><span
                class="data_container_data_data_value">{{ airstationdata.iF2 }}</span><span
                class="data_container_data_data_unit">M³/h</span></div>
          </div>
        </div>
      </div>
    </div>
    <div id="airstation_data_container">
      <img src="../../public/images/L_bottom.png" style="width:100%;height: 100%;" />
      <div id="airstation_data_list">
        <div id="airstation_data_list1">
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">每日总电能：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dpall }}</span><span
              class="airstation_data_list_data_unit">KW</span>
          </div>
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">每日总流量：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dfall }}</span><span
              class="airstation_data_list_data_unit">M³</span></div>
        </div>
        <div class="airstation_data_list_line"></div>
        <div id="airstation_data_list2">
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">瞬时总气电比：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.iger }}</span><span
              class="airstation_data_list_data_unit">kWh/M³</span>
          </div>
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">每日总气电比：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dger }}</span><span
              class="airstation_data_list_data_unit">kWh/M³</span>
          </div>
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">每月总气电比：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.mger }}</span><span
              class="airstation_data_list_data_unit">kWh/M³</span>
          </div>
        </div>
        <div class="airstation_data_list_line"></div>
        <div id="airstation_data_list3">
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">1号电表功率：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dB1 }}</span><span
              class="airstation_data_list_data_unit">kW</span>
          </div>
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">2号电表功率：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dB2 }}</span><span
              class="airstation_data_list_data_unit">kW</span>
          </div>
          <div class="airstation_data_list_data"><span class="airstation_data_list_data_title">3号电表功率：</span><span
              class="airstation_data_list_data_value">{{ airstationdata.dB3 }}</span><span
              class="airstation_data_list_data_unit">kW</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer_components></footer_components>
</template>

<script>
import topheader_components from '../layouts/topheader_components.vue'
import footer_components from '../layouts/footer_components.vue'
import http from '../utill/http.js'
export default {
  components: {
    topheader_components,
    footer_components
  },
  data() {
    return {
      controlmode: [{ "name": "本地控制", "value": 0,"cls":"" }, { "name": "远程手动", "value": 1 ,"cls":""}, { "name": "远程自动", "value": 2 ,"cls":""}],//控制模式
      runstatus1: [{ "name": "通讯断线", "value": 0 ,"cls":"","blockcls":""}, { "name": "已经停止", "value": 1,"cls":"","blockcls":""  }, { "name": "启动中", "value": 2,"cls":"thumbnail_img_colour","blockcls":"thumbnail_img_container_data_container_data_title_tag_green"  },
      { "name": "自动卸载", "value": 3,"cls":"thumbnail_img_colour" ,"blockcls":"thumbnail_img_container_data_container_data_title_tag_green"  }, { "name": "自动加载", "value":4,"cls":"thumbnail_img_colour","blockcls":"thumbnail_img_container_data_container_data_title_tag_green" }, { "name": "手动卸载", "value": 5,"cls":"thumbnail_img_colour" ,"blockcls":"thumbnail_img_container_data_container_data_title_tag_green"  },
      { "name": "手动加载", "value":6 ,"cls":"thumbnail_img_colour","blockcls":"thumbnail_img_container_data_container_data_title_tag_green"  }, { "name": "空久停车", "value": 7,"cls":"" ,"blockcls":"" }, { "name": "设备故障", "value": 8,"cls":"" ,"blockcls":"thumbnail_img_container_data_container_data_title_tag_red" },
      { "name": "紧急停车", "value": 9,"cls":"" ,"blockcls":"" }, { "name": "停机中", "value": 10,"cls":"" ,"blockcls":"" }, { "name": "重启保护", "value": 11 ,"cls":"","blockcls":"" }
      ],//空压机运行状态

      runstatus2: [{ "name": "通讯断线", "value": 0,"cls":"" }, { "name": "已经停止", "value": 1,"cls":"" }, { "name": "启动中", "value": 2 ,"cls":"thumbnail_img_s_green"  ,"blockcls":"thumbnail_img_container_data_container_data_title_tag_green" },
      { "name": "启动失败", "value":3,"cls":"" }, { "name": "正在运行", "value": 4 ,"cls":"thumbnail_img_s_green"  ,"blockcls":"thumbnail_img_container_data_container_data_title_tag_green" }, { "name": "停机中", "value": 5,"cls":"" },
      { "name": "停机事变", "value": 6,"cls":"" }, { "name": "设备故障", "value": 7,"cls":"","blockcls":"thumbnail_img_container_data_container_data_title_tag_red"}
      ],//干燥机运行状态
      airstationdata: {
        "mp": 0.0,
        "dpT1": 0.0,
        "dpT2": 0.0,
        "aF1": 0.0,
        "iF1": 0.0,
        "aF2": 0.0,
        "iF2": 0.0,
        "dfall": 0.0,
        "dpall": 0.0,
        "iger": 0.0,
        "dger": 0.0,
        "mger": 0.0,
        "acrS1": 0,
        "acrS1_name":"",
        "acrS1_cls":"",
        "acfS1": 0,
        "acfS1_name":"",
        "acfS1_cls":"",
        "acfS1_blockcls":"",
        "acP1": 0.0,
        "acT1": 0.0,
        "acrS2": 0,
        "acrS2_name":"",
        "acrS2_cls":"",
        "acfS2": 0,
        "acfS2_name":"",
        "acfS2_cls":"",
        "acfS2_blockcls":"",
        "acP2": 0.0,
        "acT2": 0.0,
        "acrS3": 0,
        "acrS3_name":"",
        "acrS3_cls":"",
        "acfS3": 0,
        "acfS3_name":"",
        "acfS3_cls":"",
        "acfS3_blockcls":"",
        "acP3": 0.0,
        "acT3": 0.0,
        "acrS4": 0,
        "acrS4_name":"",
        "acrS4_cls":"",
        "acfS4": 0,
        "acfS4_name":"",
        "acfS4_cls":"",
        "acfS4_blockcls":"",
        "acP4": 0.0,
        "acT4": 0.0,
        "acrS5": 0,
        "acrS5_name":"",
        "acrS5_cls":"",
        "acfS5": 0,
        "acfS5_name":"",
        "acfS5_cls":"",
        "acfS5_blockcls":"",
        "acP5": 0.0,
        "acT5": 0.0,
        "drS1": 0,
        "drS1_name": "",
        "drS1_cls": "",
        "dfS1": 0,
        "dfS1_name": "",
        "dfS1_cls": "",
        "dfS1_blockcls": "",
        "drS2": 0,
        "drS2_name": "",
        "drS2_cls": "",
        "dfS2": 0,
        "dfS2_name": "",
        "dfS2_cls": "",
        "dfS2_blockcls": "",
        "drS3": 0,
        "drS3_name": "",
        "drS3_cls": "",
        "dfS3": 0,
        "dfS3_name": "",
        "dfS3_cls": "",
        "dfS3_blockcls": "",
        "dB1": 0.0,
        "dB2": 0.0,
        "dB3": 0.0
      }
    };
  },
  mounted() {
    this.GetKYJandGZJDatahtml();
  },
  methods: {
    GetKYJandGZJDatahtml() {
      this.GetKYJandGZJData().then((res) => {
        if (res) {
          if (res.success == true) {
            let airstationdata_result= res.result;
           
            //控制模式
            airstationdata_result["acrS1_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.acrS1}).name;
            airstationdata_result["acrS2_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.acrS2}).name;
            airstationdata_result["acrS3_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.acrS3}).name;
            airstationdata_result["acrS4_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.acrS4}).name;
            airstationdata_result["acrS5_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.acrS5}).name;
            airstationdata_result["drS1_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.drS1}).name;
            airstationdata_result["drS2_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.drS2}).name;
            airstationdata_result["drS3_name"]=this.controlmode.find((item)=>{ return  item.value==airstationdata_result.drS3}).name;
         


            //空压机缩略图显示逻辑:根据运行状态 acfS2_name
            var runstatus1_acfS1=this.runstatus1.find((item)=>{ return  item.value==airstationdata_result.acfS1});
            airstationdata_result["acfS1_name"]=runstatus1_acfS1.name;
            airstationdata_result["acfS1_cls"]=runstatus1_acfS1.cls;
            airstationdata_result["acfS1_blockcls"]=runstatus1_acfS1.blockcls;

            var runstatus1_acfS2=this.runstatus1.find((item)=>{ return  item.value==airstationdata_result.acfS2});
            airstationdata_result["acfS2_name"]=runstatus1_acfS2.name;
            airstationdata_result["acfS2_cls"]=runstatus1_acfS2.cls;
            airstationdata_result["acfS2_blockcls"]=runstatus1_acfS2.blockcls;

            var runstatus1_acfS3=this.runstatus1.find((item)=>{ return  item.value==airstationdata_result.acfS3});
            airstationdata_result["acfS3_name"]=runstatus1_acfS3.name;
            airstationdata_result["acfS3_cls"]=runstatus1_acfS3.cls;
            airstationdata_result["acfS3_blockcls"]=runstatus1_acfS3.blockcls;


            var runstatus1_acfS4=this.runstatus1.find((item)=>{ return  item.value==airstationdata_result.acfS4});
            airstationdata_result["acfS4_name"]=runstatus1_acfS4.name;
            airstationdata_result["acfS4_cls"]=runstatus1_acfS4.cls;
            airstationdata_result["acfS4_blockcls"]=runstatus1_acfS4.blockcls;


            var runstatus1_acfS5=this.runstatus1.find((item)=>{ return  item.value==airstationdata_result.acfS5});
            airstationdata_result["acfS5_name"]=runstatus1_acfS5.name;
            airstationdata_result["acfS5_cls"]=runstatus1_acfS5.cls;
            airstationdata_result["acfS5_blockcls"]=runstatus1_acfS5.blockcls;


            //干燥机缩略图显示逻辑:根据运行状态
            var runstatus2_dfS1=this.runstatus2.find((item)=>{ return  item.value==airstationdata_result.dfS1});
            airstationdata_result["dfS1_name"]=runstatus2_dfS1.name;
            airstationdata_result["dfS1_cls"]=runstatus2_dfS1.cls;
            airstationdata_result["dfS1_blockcls"]=runstatus2_dfS1.blockcls;
            

            var runstatus2_dfS2=this.runstatus2.find((item)=>{ return  item.value==airstationdata_result.dfS2});
            airstationdata_result["dfS2_name"]=runstatus2_dfS2.name;
            airstationdata_result["dfS2_cls"]=runstatus2_dfS2.cls;
            airstationdata_result["dfS2_blockcls"]=runstatus2_dfS2.blockcls;


            var runstatus2_dfS3=this.runstatus2.find((item)=>{ return  item.value==airstationdata_result.dfS3});
            airstationdata_result["dfS3_name"]=runstatus2_dfS3.name;
            airstationdata_result["dfS3_cls"]=runstatus2_dfS3.cls;
            airstationdata_result["dfS3_blockcls"]=runstatus2_dfS3.blockcls;
           
            this.airstationdata = airstationdata_result;
          }
        }
      });

    },
    GetKYJandGZJData: async function () {
      try {
        const response = await http({
          method: 'post',
          url: '/api/services/app/FLADP/GetKYJandGZJData'
        });
        return response;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },
  }
}
</script>

<style scoped>
#page-body {
  top: 180px !important;

}

#thumbnail_img_container {
  position: absolute;
  top: 9px;
  left: 0;

  .thumbnail_img {
    width: 261.33px;
    height: 192px;
    margin-bottom: 24.5px;
    background-color: rgb(236, 234, 234);
    background-image: url('../../public/images/图-黑白.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .thumbnail_img_colour{
    background-image: url('../../public/images/图-彩色.png') !important;
  }
}

#thumbnail_img_container_data {
  width: 253.33px;
  height: 938px;
  position: absolute;
  top: 70px;
  left: 350px;

  .thumbnail_img_container_data_container {
    width: 100%;
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 133px;

    .thumbnail_img_container_data_container_data {

      .thumbnail_img_container_data_container_data_title_tag {
        width: 107px;
        height: 37px;
        background: rgba(127, 124, 124, 0.6);
        border-radius: 5px;
        border: 1px solid #A6A6A6;
        font-family: HarmonyOS_Sans_SC;
        font-size: 21px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 37px;
        text-align: center;
        font-style: normal;
      }

      .thumbnail_img_container_data_container_data_title_tag_red {
        background: rgba(184, 0, 0, 0.6) !important;
        border: 1px solid #C71818 !important;
      }

      .thumbnail_img_container_data_container_data_title_tag_green {
        background: rgba(0, 158, 0, 0.6) !important;
        border: 1px solid #00C100 !important;
      }
    }
  }
}

.data_container_data_data_value {
  width: 40px;
  height: 38px;
  font-family: HarmonyOS_Sans_SC_Bold;
  font-size: 27px;
  color: #FFFFFF;
  line-height: 37px;
  text-align: left;
  font-style: normal;
}

.data_container_data_data_unit {
  width: 23px;
  height: 27px;
  font-family: HarmonyOS_Sans_SC;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 26px;
  text-align: left;
  font-style: normal;
}

#airstation_data_container {
  width: 1365.33px;
  height: 234.67px;
  position: absolute;
  bottom: -85px;
  right: 180px;
}

#airstation_data_list {
  width: calc(100% - 120px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  .airstation_data_list_line {
    width: 1px;
    height: 120px;
    border: 1px solid rgba(255, 255, 255, 0.19);
  }

  .airstation_data_list_data_title {
    display: inline-block;
    height: 30px;
    font-family: HarmonyOS_Sans_SC;
    font-size: 21px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 30px;
    text-align: left;
    font-style: normal;
  }

  .airstation_data_list_data_value {
    height: 38px;
    font-family: HarmonyOS_Sans_SC_Bold;
    font-size: 27px;
    color: #FFFFFF;
    line-height: 37px;
    text-align: left;
    font-style: normal;
    margin-left: 16px;
    margin-right: 11px;
  }

  .airstation_data_list_data_unit {
    width: 56px;
    height: 38px;
    font-family: HarmonyOS_Sans_SC;
    font-size: 27px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 37px;
    text-align: left;
    font-style: normal;
  }
}

.tag_title {
  width: 73px;
  height: 37px;
  background: rgba(67, 131, 255, 0.8);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 21px 0px;
  font-family: HarmonyOS_Sans_SC_Bold;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 37px;
  text-shadow: 0px 3px 5px rgba(0, 75, 96, 0.5);
  text-align: center;
  font-style: normal;
}

.tag_title_red {
  background: rgba(200, 0, 0, 0.8) !important;
}

#zylg_container {
  position: absolute;
  top: 315px;
  left: 830px;
}

#thumbnail_s_img_container {
  position: absolute;
  top: 100px;
  right: 877px;

  .thumbnail_img_s {
    width: 138.72px;
    height: 170.67px;
    margin-bottom: 151px;
    background-image: url('../../public/images/设备_blue.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .thumbnail_img_s_green{
    background-image: url('../../public/images/设备_green.png') !important;
  }
}

.thumbnail_s_img_container_data {
  right: 589px !important;
  left: initial !important;
  top: 165px !important;
  .thumbnail_s_img_container_data_container{
    margin-bottom: 246px !important;
  }
}

#ld_data_container {
  position: absolute;
  top: 260px;
  right: 510px;
}

.thumbnail_img_container_data_container_ld {
  margin-bottom: 400px;
}

.thumbnail_img_container_data_container_data_title {
  width: 96px;
  height: 34px;
  font-family: HarmonyOS_Sans_SC;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 34px;
  text-align: left;
  font-style: normal;
}

#xsll_data_container {
  position: absolute;
  top: 170px;
  right: 78px;
}

.thumbnail_img_container_data_container_xsll {
  margin-bottom: 430px;
}
</style>
